body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.center {
  margin: 80px auto;
  width: 40%;
  border: 'yellow';
  padding: 10px;
}

textarea:focus, input:focus{
  outline: none;
}

button{
  padding: 15px 40px;
  background: #000;
  border:0;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  position: relative;
  transition:         all .4s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  display: block;
  margin: 12px 0px;
  border-radius: 1px;
}

button::after,
button::before{
  content: "";
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
    opacity: 0;
  transition:         all .4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

button::after{
    width: 18px;
    height: 1px;
    background: white;
    transform: translateX(-3px);
    margin-top: 0px;
}

button::before{
    content: "";
    transform: rotate(-135deg) translateX(50%);
    width: 10px;
    height: 10px;
    background: transparent;
    border-left: 1px solid white;
    border-bottom: 1px solid white;
  margin-top: -1px;
}


button:hover{
  padding: 15px 60px 15px 20px;
}

button:hover::after,
button:hover::before{
  opacity: 1;
  right: 15px;
}
